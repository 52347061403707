export default function initMoveText() {
  ResponsiveHelper.addRange({
    '..1023': {
      on: function () {
        // jQuery('.move-parent').detach().appendTo('.mobile-clone');
      },
    },
    '1024..': {
      on: function() {
        // jQuery('.move-parent').detach().prependTo('.program-slider');
      },
    },
  });
  ResponsiveHelper.addRange({
    '..1023': {
      on: function () {
        jQuery('.move-btn').detach().appendTo('.btn-clone');
      },
    },
    '1024..': {
      on: function() {
        jQuery('.move-btn').detach().appendTo('.move-this');
      },
    },
  });
  ResponsiveHelper.addRange({
    '..1023': {
      on: function () {
        jQuery('.move-img').detach().appendTo('.move-image-here');
      },
    },
    '1024..': {
      on: function() {
        jQuery('.move-img').detach().appendTo('.move-image');
      },
    },
  });
  ResponsiveHelper.addRange({
    '..1023': {
      on: function () {
        jQuery('.embed-holder').detach().appendTo('.move-embed-here');
      },
    },
    '1024..': {
      on: function() {
        jQuery('.embed-holder').detach().appendTo('.move-embed-back');
      },
    },
  });
}
