import 'Plugins/parallaxPlugin';


export default function initParallax() {
	if (window.ParallaxScroll) {
		var $win = jQuery(window);

		function setProps(e) {
			jQuery('[data-parallax]').each(function () {
				var $el = jQuery(this);

				// node.getBBox() for ie11
				var elBox = {
					width: ($el[0].nodeName === 'svg') ? (getCssSize(jQuery(this)[0]).width) : $el.outerWidth(),
					height: ($el[0].nodeName === 'svg') ? (getCssSize(jQuery(this)[0]).height) : $el.outerHeight()
				};

				var props = $el.data('parallax');

				if ($el.data('original-props') == undefined) {
					$el.data('original-props', JSON.parse(JSON.stringify(props)));
				}

				// for window resize
				if (e) {
					props = $el.data('original-props');
				}

				if (props != undefined) {
					for (var prop in props) {
						if (prop == 'x' || prop == 'y') {
							var num = parseInt(props[prop].replace(/\D*/gi, ''));
							var sign = props[prop].indexOf('-') > -1 ? '-' : '';

							// get vw/vh
							if (props[prop].indexOf('vh') > -1 || props[prop].indexOf('vw') > -1) {
								setPropToData($el, prop, sign + (prop == 'x' ? $win.outerWidth() * num / 100 : $win.outerHeight() * num / 100));
								continue;
							}

							// get percentage
							if (props[prop].indexOf('%') > -1) {
								setPropToData($el, prop, sign + (prop == 'x' ? elBox.width * num / 100 : elBox.height * num / 100));
								continue;
							}

							// get offset from dom element bounds
							if (isNaN(props[prop])) {
								var boundElement = jQuery(props[prop].replace(/@.*/gi, ''));
								if (boundElement.length) {
									// default top offset
									var direction = 'top';
									var boundElementVal = boundElement.offset()[direction]

									if (props[prop].indexOf('@') > -1) {
										// optional bottom offset
										direction = props[prop].match(/@.*/gi, '')[0].replace('@', '');
										if (direction === 'bottom') {
											boundElementVal += boundElement.outerHeight();
										}
									}

									setPropToData($el, prop, -1 * ($el.offset().top - boundElementVal + $el.outerHeight()));
								}
								continue;
							}
						}

						if (isNaN(props[prop])) {
							// get offset from dom element
							if (prop === 'from-scroll') {
								var startElement = jQuery(props[prop]);

								if (startElement.length) {
									setPropToData($el, prop, startElement.offset().top - $win.outerHeight());
								}
							}
						}
					}
				}
			});
		}

		function getCssSize(el) {
			var styles = getComputedStyle(el);
			var re = /[A-Za-z]*/g;

			return {
				width: styles.width.replace(re, ''),
				height: styles.height.replace(re, '')
			};
		}

		function setPropToData($el, prop, val) {
			//modify data object
			$el.data('parallax')[prop] = val;
			//set modified object to data-attrib
			$el.attr('data-parallax', JSON.stringify($el.data('parallax')));
		}

		// transform data attrib
		setProps();
		// init parallax
		ParallaxScroll.init();
		// bind resize handler
		$win.on('resize orientationchange', setProps);
	}
}
