import 'Plugins/accordionPlugin';

// accordion menu init
export default function initAccordion() {
	jQuery('.accordion').slideAccordion({
		opener: '> a',
		slider: '> ul',
		animSpeed: 300
	});

	jQuery('.department-accordion').slideAccordion({
		opener: '.department-accordion-open',
		slider: '.department-accordion-slide',
		animSpeed: 300
	});
}
