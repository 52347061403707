import 'Plugins/stickyScrollBlockPlugin';

// initialize fixed blocks on scroll
export default function initStickyScrollBlock() {
  ResponsiveHelper.addRange({
    '1024..': {
      on: function() {
        jQuery('#header').stickyScrollBlock({
          setBoxHeight: true,
          activeClass: 'fixed-position',
          positionType: 'fixed',
          extraTop: function() {
            var totalHeight = 0;
            jQuery('0').each(function() {
              totalHeight += jQuery(this).outerHeight();
            });
            return totalHeight;
          }
        });
      },
      off: function() {
        jQuery('#header').stickyScrollBlock('destroy');
      }
    }
  });

  ResponsiveHelper.addRange({
    '1024..': {
      on: function() {
        jQuery('.social-bar-float').stickyScrollBlock({
          setBoxHeight: false,
          activeClass: 'fixed-position',
          container: '.main',
          positionType: 'fixed',
          extraTop: function() {
            var totalHeight = 0;
            jQuery('#header').each(function() {
              totalHeight += jQuery(this).outerHeight();
            });
            return totalHeight;
          }
        });
      },
      off: function() {
        jQuery('.social-bar-float').stickyScrollBlock('destroy');
      }
    }
  });
}
