// add classes if item has dropdown
export default function initDropDownClasses() {
	jQuery('.side-nav li').each(function() {
		var item = jQuery(this);
		var drop = item.find('ul');
		var link = item.find('a').eq(0);
		if (drop.length) {
			item.addClass('has-drop-down');
			if (link.length) link.addClass('has-drop-down-a').append('<span class="plus"></span>');
		}
	});
}