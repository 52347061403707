import ready, { HTML } from './utils';
import './plugins/responsiveHelper';
import initMainMenu from './components/mainMenu';
import initStickyScrollBlock from './components/stickyScrollBlock';
import initOpenClose from './components/openClose';
import initAccordion from './components/accordion';
import initMobileNav from './components/burgerMenu';
import initDropDownClasses from './components/hasDropdown';
import initAnchors from './components/smoothScroll';
import initNavigationSelect from './components/navSelect';
import initMoveText from './components/moveOutText';
import initSlickCarousel from './components/slickCarousel';
import initTabs from './components/tabs';
import initCounter from './components/countUp';
import initParallax from './components/parallax';
import initCustomVideoPlayer from './components/customVideoPlayer';
import scrollToTop from './components/scrollToTop';
import initInViewport from './components/initInViewport';
import initFormValidation from './components/formValidation';

ready(() => {
  HTML.classList.add('is-loaded');
  initMainMenu();
  initStickyScrollBlock();
  initOpenClose();
  initAccordion();
  initMobileNav();
  initDropDownClasses();
  initAnchors();
  initNavigationSelect();
  initMoveText();
  initSlickCarousel();
  initTabs();
  initCounter();
  initParallax();
  initCustomVideoPlayer();
  scrollToTop();
  initInViewport();
  initFormValidation();
});
