import 'Plugins/slickCarouselPlugin';

// SlickCarousel init
export default function initSlickCarousel() {
  jQuery('.rows-gallery').slick({
    slidesToScroll: 1,
    rows: 2,
    slidesToShow: 1,
    slidesPerRow: 2,
    prevArrow: '<button class="slick-prev"><i class="icon-arrow-left"></i></button>',
    nextArrow: '<button class="slick-next"><i class="icon-arrow-right"></i></button>',
    dots: true,
    dotsClass: 'slick-dots',
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          rows: 2,
          slidesToShow: 1,
          slidesPerRow: 1,
        },
      },
    ],
  });

  jQuery('.fw-gallery').slick({
    slidesToScroll: 1,
    rows: 0,
    prevArrow: '<button class="slick-prev"><i class="icon-arrow-left"></i></button>',
    nextArrow: '<button class="slick-next"><i class="icon-arrow-right"></i></button>',
    dots: true,
    dotsClass: 'slick-dots',
    autoplay: true,
  });

  jQuery('.rows-gallery-alt').slick({
    slidesToScroll: 1,
    rows: 2,
    slidesToShow: 2,
    slidesPerRow: 2,
    prevArrow: '<button class="slick-prev"><i class="icon-arrow-left"></i></button>',
    nextArrow: '<button class="slick-next"><i class="icon-arrow-right"></i></button>',
    dots: true,
    dotsClass: 'slick-dots',
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesPerRow: 2,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 2,
          slidesToShow: 1,
          slidesPerRow: 1,
        },
      },
    ],
  });

  jQuery('.academic-programs .wrap').each(function() {
    const holder = jQuery(this);
    const cntnt = holder.html();

    ResponsiveHelper.addRange(
      {
      '..1023': {
        on: function() {
          holder.find('.move-parent').detach().appendTo('.mobile-clone');
          holder.find('.program-slider').slick({
            slidesToScroll: 1,
            rows: 0,
            prevArrow: '<button class="slick-prev"><i class="icon-arrow-left"></i></button>',
            nextArrow: '<button class="slick-next"><i class="icon-arrow-right"></i></button>',
            dots: true,
            dotsClass: 'slick-dots',
            autoplay: true,
            autoplay: false,
            mobileFirst: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            responsive: [
              {
                breakpoint: 600,
                settings: {
                  slidesToScroll: 2,
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 1023,
                settings: 'unslick',
              },
            ],
          });
        },
      },
      '1024..': {
        on: function() {
          setTimeout(() => {
            holder.empty().html(cntnt);
          }, 0);
        },
      },
    }
    );
  });
}
