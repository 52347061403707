import 'Plugins/burgerMenuPlugin';

// mobile menu init
export default function initMobileNav() {
	jQuery('body').mobileNav({
		menuActiveClass: 'side-nav-active',
		menuOpener: '.side-nav-opener',
		hideOnClickOutside: true,
		menuDrop: '.side-nav'
	});
}
