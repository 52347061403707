export default function scrollToTop() {
  let timer;
  const btns = jQuery('.scroll-to-top');
  const win = jQuery(window);

  if (!btns.length) return;

  const scrollHandler = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      btns.toggleClass('hide', document.body.scrollHeight / 2 >= win.scrollTop());
    }, 100);
  };

  scrollHandler();
  win.on('scroll', scrollHandler);
}
