import 'Plugins/tabsPlugin';

// content tabs init
export default function initTabs() {
  const win = jQuery(window);

  jQuery('.tabset').tabset({
    tabLinks: 'a',
    defaultTab: true,
    onChange: function() {
      win.trigger('resize');
    }
  });

  jQuery('.nav-select').tabset({
    tabLinks: 'a',
    defaultTab: true,
    addToParent: true,
  });
}
