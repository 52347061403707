import 'Plugins/openClosePlugin';

// open-close init
export default function initOpenClose() {
  jQuery('.open-close').openClose({
    activeClass: 'active',
    opener: '.opener',
    slider: '.slide',
    animSpeed: 400,
    hideOnClickOutside: true,
    effect: 'slide'
  });

  jQuery('.nav-menu-item').each(function() {
    const holder = jQuery(this);
    const page = jQuery('html, body');
    const body = jQuery('body');
    const noScrollClass = 'no-scroll';
    const parent = holder.parent();
    const items = parent.find('.nav-menu-item').filter((i, item) => !jQuery(item).is(holder));

    ResponsiveHelper.addRange({
      '..1023': {
        on: function () {
          if (holder.data('OpenClose')) {
            holder.data('OpenClose').destroy();
          }
        }
      },
      '1024..': {
        on: function () {
          holder.openClose({
            activeClass: 'drop-active',
            opener: '.opener-menu-item',
            slider: '.drop-holder',
            animSpeed: 10,
            effect: 'fade',
            animStart: function(flag) {
              if (flag) {
                items.each((i, item) => {
                  jQuery(item).data('OpenClose').hideSlide();
                })
              }
            },
            animEnd: function(flag) {
              body.toggleClass(noScrollClass, flag);
            }
          });
        }
      },
    });
  });

  jQuery('.footer').each(function() {
    const footer = jQuery(this);
    const page = jQuery('body, html');

    footer.openClose({
      activeClass: 'active',
      opener: '.footer-opener',
      slider: '.footer-inner',
      animSpeed: 400,
      effect: 'slide',
      animEnd: function(flag) {
        if (flag) {
          page.stop().animate({
            scrollTop: footer.offset().top
          }, 800);
        }
      }
    });
  });
}
