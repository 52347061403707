// desktop tab menu plugin
; (function ($) {
	'use strict';

	function DesktopTabMenu(options) {
		this.options = $.extend({
			holder: null,
			items: '.menu-item',
			links: '>a',
			delay: 10,
			activeClass: 'hover',
			focusAttr: 'data-focused',
			hasChildrenClass: 'menu-item-has-children',
			altClass: 'right-position',
			drop: '> .drop-holder',
			mainItemClass: 'main-item',
		}, options);
		this.init();
	}
	DesktopTabMenu.prototype = {
		init: function () {
			if (this.options.holder) {
				this.findElements();
				this.attachEvents();
				this.makeCallback('onInit', this);
			}
		},
		findElements: function () {
			this.holder = $(this.options.holder);
			this.holder.find('> ' + this.options.items).addClass(this.options.mainItemClass);
			this.menuItems = this.holder.find(this.options.items);
			this.menuLinks = this.holder.find(this.options.items + ' ' + this.options.links);
			this.timer;
			this.win = jQuery(window);
		},
		openDrop: function (item) {
			item.addClass(this.options.activeClass);
			this.makeCallback('onDropOpen', this);
		},
		closeItem: function (menuItem) {
			menuItem.removeClass(this.options.activeClass);
			this.makeCallback('onDropClose', this);
		},
		closeDrop: function () {
			var self = this;

			setTimeout(() => {
				var opened = self.menuItems
					.filter('.' + self.options.activeClass)
					.filter(':not(:has([' + self.options.focusAttr + ']))')
					.removeClass(self.options.activeClass);

				if (opened.length) {
					this.makeCallback('onDropClose', this);
				}

				var menuFocus = self.holder.find('[' + self.options.focusAttr + ']');
				if (menuFocus.length === 0) self.menuItems.removeClass(self.options.activeClass);
			}, 0);
		},
		attachEvents: function () {
			var self = this;

			this.tabDirectionHandler = function (e) {
				if (e.which === 9 && e.shiftKey) {
					self.tabDirection = -1;
				} else if (e.which === 9) {
					self.tabDirection = 1;
				}
			};

			this.focusHandler = function (e) {
				var link = jQuery(e.target).attr(self.options.focusAttr, true);
				var parent = link.closest(self.options.items);

				if (parent.hasClass(self.options.hasChildrenClass) && parent.hasClass(self.options.mainItemClass)) {
					self.openDrop(parent);
				}
			};

			this.enterHandler = function (e) {
				var link = jQuery(this);
				var parent = link.closest(self.options.items);

				// if (e.keyCode === 13 && parent.hasClass(self.options.hasChildrenClass) && !parent.hasClass(self.options.mainItemClass)) {
				if (e.keyCode === 13 && parent.hasClass(self.options.hasChildrenClass)) {
					e.preventDefault();
					e.stopPropagation();
					e.returnValue = false;
					e.cancelBubble = true;
					self.openDrop(parent);
					return false;
				}
			};

			this.blurHandler = function (e) {
				jQuery(this).removeAttr(self.options.focusAttr);

				self.closeDrop();
			};

			this.resizeHandler = function () {
				clearTimeout(self.timer);
				self.timer = setTimeout(function () {
					self.menuItems.filter('.' + self.options.hasChildrenClass).each(function () {
						var item = jQuery(this);
						var drop = item.find(self.options.drop);
						if (!drop.length) return;

						item.removeClass(self.options.altClass);
						drop.offset().left + drop.outerWidth() > self.win.width() && item.addClass(self.options.altClass);
					}, 100);
				});
			};

			this.resizeHandler();
			this.menuLinks
				// .on('focus', this.focusHandler)
				// .on('blur', this.blurHandler)
				.on('keyup keydown', this.enterHandler);

			this.win.on('resize orientationchange', this.resizeHandler);
		},
		destroy: function () {
			this.menuItems
				.removeClass(this.options.activeClass)
				.removeClass(this.options.altClass);
			this.holder.find('[' + this.options.focusAttr + ']').removeAttr(this.options.focusAttr);

			this.menuLinks
				.off('focus', this.focusHandler)
				.off('blur', this.blurHandler);
			this.win.off('resize orientationchange', this.resizeHandler);
			this.makeCallback('onDestroy', this);
		},
		makeCallback: function (name) {
			if (typeof this.options[name] === 'function') {
				var args = Array.prototype.slice.call(arguments);
				args.shift();
				this.options[name].apply(this, args);
			}
		}
	};

	$.fn.desktopTabMenu = function (options) {
		return this.each(function () {
			$(this).data('DesktopTabMenu', new DesktopTabMenu($.extend(options, { holder: this })));
		});
	};
}(jQuery));
