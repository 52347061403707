import '../plugins/contentPopup';
import '../plugins/desktopTabMenu';

// MainMenu init
export default function initMainMenu() {
  var isSmall = jQuery(window).width() < 768;

  jQuery('.main-menu').each(function () {
    var menu = jQuery(this);
    var mainVavigation = jQuery('.main-navigation');
    var subItems = menu.find('.menu-item-has-children');
    var allLinks = menu.find('a, button');

    function mobileDrops() {
      subItems.each(function () {
        var box = jQuery(this);

        box.contentPopup({
          mode: 'click',
          popup: '> .drop-holder',
          btnOpen: '> a',
          btnClose: '> .drop-holder > .drop-top > .drop-close',
          openClass: 'drop-active',
          hideOnClickLink: 'drop-active',
          hideOnClickOutside: false,
          onInit: function (api) {
            var currentLink = api.btnOpen;
            var href = currentLink.attr('href');
            var linkEl = currentLink.find('.nav-item-link').length ? currentLink.find('.nav-item-link') : currentLink;
            var linkText = linkEl.text();
            var dropMenu = api.popup.find('> .drop-body > .sub-menu');
            var dropTitle = api.popup.find('> .drop-body > .drop-title');

            if (!dropTitle.length) {
              jQuery('<a href="' + href + '" class="drop-title" >' + linkText + '</a>').insertBefore(dropMenu);
            }
          },
          onShow: function (flag, api) {
            api.holder.closest('.drop-holder').addClass('inner-drop-open');
          },
          onHide: function (flag, api) {
            api.holder.closest('.drop-holder').removeClass('inner-drop-open');
          },
          onDestroy: function (api) {
            api.holder.closest('.drop-holder').removeClass('inner-drop-open');
            allLinks.removeAttr('tabindex').css({ display: '', });
          }
        });
      });

      jQuery('body').mobileNav({
        menuActiveClass: 'nav-active',
        menuOpener: '.mobile-nav-opener',
        hideOnClickOutside: true,
        menuDrop: '.main-navigation',
        onInit: function () {
          mainVavigation.attr('aria-hidden', true);
        },
        onShow: function () {
          mainVavigation.attr('aria-hidden', false);
        },
        onHide: function () {
          mainVavigation.attr('aria-hidden', true);
          subItems.each(function () {
            var box = jQuery(this),
              api = box.data('ContentPopup');
            api && api.hidePopup();
          });
        }
      });
    }

    function desktopDrops() {
      menu.desktopTabMenu({
        altClass: 'right-position',
      });
    }

    ResponsiveHelper.addRange({
      '..1023': {
        on: function () {
          isSmall = true;

          menu.data('DesktopTabMenu') && menu.data('DesktopTabMenu').destroy();
          mobileDrops();
        },
        off: function () {
          jQuery('body').mobileNav('destroy');
        }
      },
      '1024..': {
        on: function () {
          isSmall = false;

          subItems.each(function () {
            var subItem = jQuery(this);
            subItem.data('ContentPopup') && subItem.data('ContentPopup').destroy();
          });

          desktopDrops();
          mainVavigation.removeAttr('aria-hidden');
        }
      },
    });
  });
}
