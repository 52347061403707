/* eslint-disable */


/*
 * jQuery form validation plugin
 */
;(function($) {

	'use strict';

	var FormValidation = (function() {
		var Validator = function($field, $fields) {
			this.$field = $field;
			this.$fields = $fields;
		};

		Validator.prototype = {
			reg: {
				email: '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$',
				number: '^[0-9]+$'
			},

			checkField: function() {
				return {
					state: this.run(),
					$fields: this.$field.add(this.additionalFields)
				}
			},

			run: function() {
				var fieldType;

				switch (this.$field.get(0).tagName.toUpperCase()) {
					case 'SELECT':
						fieldType = 'select';
						break;

					case 'TEXTAREA':
						fieldType = 'text';
						break;

					default:
						fieldType = this.$field.data('type') || this.$field.attr('type');
				}

				var functionName = 'check_' + fieldType.replace(/-/g,'_');
				var state = true;

				if ($.isFunction(this[functionName])) {
					state = this[functionName]();

					if (state && this.$field.data('confirm')) {
						state = this.check_confirm();
					}
				}

				return state;
			},

			check_email: function() {
				var value = this.getValue();
				var required = this.$field.data('required');
				var requiredOrValue = required || value.length;

				if ((requiredOrValue && !this.check_regexp(value, this.reg.email))) {
					return false;
				}

				return requiredOrValue ? true : null;
			},

			check_number: function() {
				var value = this.getValue();
				var required = this.$field.data('required');
				var isNumber = this.check_regexp(value, this.reg.number);
				var requiredOrValue = required || value.length;

				if (requiredOrValue && !isNumber) {
					return false;
				}

				var min = this.$field.data('min');
				var max = this.$field.data('max');
				value = +value;

				if ((min && (value < min || !isNumber)) || (max && (value > max || !isNumber))) {
					return false;
				}

				return (requiredOrValue || min || max) ? true : null;
			},

			check_tel: function() {
				var value = this.getValue();
				var required = this.$field.data('required');
				var isNumber = this.check_regexp(value, this.reg.number);
				var requiredOrValue = required || value.length;

				if (requiredOrValue && !isNumber) {
					return false;
				}

				var min = this.$field.data('min');
				var max = this.$field.data('max');
				value = +value;

				if ((min && (value < min || !isNumber)) || (max && (value > max || !isNumber))) {
					return false;
				}

				return (requiredOrValue || min || max) ? true : null;
			},

			check_password: function() {
				return this.check_text();
			},

			check_text: function() {
				var value = this.getValue();
				var required = this.$field.data('required');

				if (this.$field.data('required') && !value.length) {
					return false;
				}

				var min = +this.$field.data('min');
				var max = +this.$field.data('max');

				if ((min && value.length < min) || (max && value.length > max)) {
					return false;
				}

				var regExp = this.$field.data('regexp');

				if (regExp && !this.check_regexp(value, regExp)) {
					return false;
				}

				return (required || min || max || regExp) ? true : null;
			},

			check_confirm: function() {
				var value = this.getValue();
				var $confirmFields = this.$fields.filter('[data-confirm="' + this.$field.data('confirm') + '"]');
				var confirmState = true;

				for (var i = $confirmFields.length - 1; i >= 0; i--) {
					if ($confirmFields.eq(i).val() !== value || !value.length) {
						confirmState = false;
						break;
					}
				}

				this.additionalFields = $confirmFields;

				return confirmState;
			},

			check_select: function() {
				var required = this.$field.data('required');

				if (required && this.$field.get(0).selectedIndex === 0) {
					return false;
				}

				return required ? true : null;
			},

			check_radio: function() {
				var $fields = this.$fields.filter('[name="' + this.$field.attr('name') + '"]');
				var required = this.$field.data('required');

				if (required && !$fields.filter(':checked').length) {
					return false;
				}

				this.additionalFields = $fields;

				return required ? true : null;
			},

			check_checkbox: function() {
				var required = this.$field.data('required');

				if (required && !this.$field.prop('checked')) {
					return false;
				}

				return required ? true : null;
			},

			check_at_least_one: function() {
				var $fields = this.$fields.filter('[data-name="' + this.$field.data('name') + '"]');

				if (!$fields.filter(':checked').length) {
					return false;
				}

				this.additionalFields = $fields;

				return true;
			},

			check_regexp: function(val, exp) {
				return new RegExp(exp).test(val);
			},

			getValue: function() {
				if (this.$field.data('trim')) {
					this.$field.val($.trim(this.$field.val()));
				}

				return this.$field.val();
			}
		};

		var publicClass = function(form, options) {
			this.$form = $(form).attr('novalidate', 'novalidate');
			this.options = options;
		};

		publicClass.prototype = {
			buildSelector: function(input) {
				return input + ':not(' + this.options.skipDefaultFields + (this.options.skipFields ? ',' + this.options.skipFields : '') + ')';
			},

			init: function() {
				this.fieldsSelector = this.buildSelector(':input');

				this.$form
					.on('submit', this.submitHandler.bind(this))
					.on('keyup blur', this.fieldsSelector, this.changeHandler.bind(this))
					.on('change', this.buildSelector('select'), this.changeHandler.bind(this))
					.on('focus', this.fieldsSelector, this.focusHandler.bind(this));
			},

			submitHandler: function(e) {
				var self = this;
				var $fields = this.getFormFields();

				this.getClassTarget($fields)
					.removeClass(this.options.errorClass + ' ' + this.options.successClass);

				this.setFormState(true);

				$fields.each(function(i, input) {
					var $field = $(input);
					var $classTarget = self.getClassTarget($field);

					// continue iteration if $field has error class already
					if ($classTarget.hasClass(self.options.errorClass)) {
						return;
					}

					self.setState(new Validator($field, $fields).checkField());
				});

				return this.checkSuccess($fields, e);
			},

			checkSuccess: function($fields, e) {
				var self = this;
				var success = this.getClassTarget($fields || this.getFormFields())
								  .filter('.' + this.options.errorClass).length === 0;

				if (e && success && this.options.successSendClass) {
					e.preventDefault();

					$.ajax({
						url: this.$form.removeClass(this.options.successSendClass).attr('action') || '/',
						type: this.$form.attr('method') || 'POST',
						data: this.$form.serialize(),
						success: function() {
							self.$form.addClass(self.options.successSendClass);
						}
					});
				}

				this.setFormState(success);

				return success;
			},

			changeHandler: function(e) {
				var $field = $(e.target);

				if ($field.data('interactive')) {
					this.setState(new Validator($field, this.getFormFields()).checkField());
				}

				this.checkSuccess();
			},

			focusHandler: function(e) {
				var $field = $(e.target);

				this.getClassTarget($field)
					.removeClass(this.options.errorClass + ' ' + this.options.successClass);

				this.checkSuccess();
			},

			setState: function(result) {
				this.getClassTarget(result.$fields)
					.toggleClass(this.options.errorClass, result.state !== null && !result.state)
					.toggleClass(this.options.successClass, result.state !== null && this.options.successClass && !!result.state);
			},

			setFormState: function(state) {
				if (this.options.errorFormClass) {
					this.$form.toggleClass(this.options.errorFormClass, !state);
				}
			},

			getClassTarget: function($input) {
				return (this.options.addClassToParent ? $input.closest(this.options.addClassToParent) : $input);
			},

			getFormFields: function() {
				return this.$form.find(this.fieldsSelector);
			}
		};

		return publicClass;
	}());

	$.fn.formValidation = function(options) {
		options = $.extend({}, {
			errorClass: 'input-error',
			successClass: '',
			errorFormClass: '',
			addClassToParent: '',
			skipDefaultFields: ':button, :submit, :image, :hidden, :reset',
			skipFields: '',
			successSendClass: ''
		}, options);

		return this.each(function() {
			new FormValidation(this, options).init();
		});
	};
}(jQuery));